// Operator argument types
export type Requireable = any
export type Ord = number | string
export type Num = number
export type Eq = any

// Operator interfaces
export type Operator<B, I> = (baseValue: B) => (inputValue: I) => boolean
export type RequireableOperator = () => (inputValue: Requireable) => boolean
export type OrdOperator<T = Ord> = Operator<T, T>
export type OrdAlphaOperator = Operator<string, string>
export type NumOperator = Operator<number, number>
export type EqOperator<T = Eq> = Operator<T, T>

export type GetValidatorBaseValue<B, F, P> = (allValues?: F, componentProps?: P, fieldName?: string) => B
export type GetValidatorErrorMessage<B, I, F, P> = (validationBaseValue: B, inputValue: I, allValues: F, componentProps: P, fieldName: string) => string
export type ParseValue<R, V> = (input: R) => V
export type ValidationError = string | undefined
export type Validator<I, F, P> = (inputValue: I, allValues?: F, props?: P, name?: keyof F) => ValidationError

export enum ValidateTrigger {
  Always = 'Always',
  IfInputIsSet = 'IfInputIsSet',
  IfBaseIsSet = 'IfBaseIsSet',
  IfInputAndBaseAreSet = 'IfInputAndBaseAreSet',
}

interface IValidatorCreatorProps<B, I, F, P, BT = B, IT = I> {
  validateOverride?: ValidateTrigger
  getValidatorBaseValueOverride?: GetValidatorBaseValue<B, F, P> | B
  getValidatorErrorMessageOverride?: GetValidatorErrorMessage<B, I, F, P> | string
  getValueFromInputOverride?: ParseValue<IT, I>
  getValueFromBaseOverride?: ParseValue<BT, B>
}

export type ValidatorCreator<B, I, F, P, BT = B, IT = I> = (props?: IValidatorCreatorProps<B, I, F, P, BT, IT>) => Validator<I, F, P>

export interface IFormFieldSpec {
  label: string
  path: string
}

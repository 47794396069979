import {
  Button,
  Paper,
  // Switch,
} from '@material-ui/core'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { reduxForm } from 'redux-form'
// import RaisedButton from 'material-ui/RaisedButton'
// import Toggle from 'material-ui/Toggle'

import { compose } from '../../../../lib/core/functional'
import { collection, required } from '../../../../lib/form/validation'

import { NadaLogo } from '../../components/common/NadaLogo'
import { InputField } from '../../components/inputs/InputField'
import { SwitchField } from '../../components/inputs/SwitchField'

import { Navbar } from '../../components/common/Navbar'


import * as styles from './Login.css'

// import { action } from 'store'
// import loginAction from './action'

const mapState = () => ({
})

const mapDispatch = () => ({
})

interface ILoginFormFields {
  username: string
  password: string
  rememberMe: boolean
}

const require = required.requiredCreator({
  getValidatorErrorMessageOverride: 'Polje je obvezno',
})
const longerThan8 = collection.minLengthCreator(8)({
  getValidatorErrorMessageOverride: (min) => `Lozinka mora imati najmanje ${min} znakova`,
})

export const Login = (compose as any)(
  connect(mapState, mapDispatch),
  reduxForm({ form: 'LoginPage.Login' }),
)(
  class Login extends React.PureComponent<any> {

    public render() {
      // const { current, loading, logout, username, password, handleSubmit } = this.props
      const { current, loading, handleSubmit } = this.props

      return (
        <div className={styles.Wrapper}>
          <Navbar />
          <div className={styles.PaperWrapper}>
            <Paper elevation={5} className={styles.Paper}>
              <form onSubmit={handleSubmit} className={styles.Form}>
                <div className={styles.Header}>
                  <NadaLogo />
                </div>
                { current
                  ? (
                    <div>
                      <p className='login-message'>Već ste prijavljeni, želite li se odjaviti?</p>

                      <div className={styles.Footer}>
                        <Button
                          disabled={loading}
                          color='primary'
                          onClick={() => console.log('#TODO: logout command')}
                        >
                          Odjava
                        </Button>
                      </div>
                    </div>
                  )
                  : (
                    <React.Fragment>
                      <InputField<ILoginFormFields>
                        name='username'
                        className={styles.InputField}
                        placeholder='Unesite korisničko ime ili email'
                        label='Korisničko ime ili email'
                        validate={[
                          require,
                        ]}
                      />
                      <InputField<ILoginFormFields>
                        name='password'
                        placeholder='Unesite Lozinku'
                        label='Lozinka'
                        validate={[
                          require,
                          longerThan8,
                        ]}
                      />
                      <div className={styles.RememberMeRow}>
                        <SwitchField
                          name='rememberMe'
                          label='Zapamti me'
                        />
                        <Link to='/signup'>Besplatna Registracija</Link>
                      </div>
                      <div className={styles.Footer}>
                        <Button
                          disabled={loading}
                          color='secondary'
                          variant='contained'
                          onClick={() => console.log('#TODO: login command')}
                        >
                          Prijava
                        </Button>
                      </div>
                    </React.Fragment>
                  )
                }
              </form>
            </Paper>
          </div>
        </div>
      )
    }
  },
)

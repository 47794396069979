import { withStyles } from '@material-ui/core/styles'
import { ConnectedRouter } from 'connected-react-router'
import * as React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { history } from './store'

// import { AboutUs, Activities, Bodies, Candidacies, Documents, Home, Login, News, NewsLetter, Propositions, Rankings, Signup } from './page'

import { Login } from './pages/Login'

import { Documents } from './pages/Documents'
import { Home } from './pages/Home'
import { Maintenance } from './pages/Maintenance'

const styles: any = {
  app: {
    width: '100%',
    height: '100%',
    minWidth: 1024,
    boxSizing: 'border-box',
    position: 'relative',
  },
  version: {
    position: 'absolute',
    bottom: 16,
    right: 16,
  },
}

const mapState = () => ({})
const mapDispatch = () => ({})

class BareRoutes extends React.Component<any, any> {

  componentDidMount() {
    console.debug('fetch session state data')
  }

  render() {
    const { classes } = this.props

    return (
      <ConnectedRouter history={history}>
        <div className={classes.app}>
          <Route exact path='/' render={() => <Home />} />

          <Route exact path='/login' render={() => <Login />} />
          <Route exact path='/signup' render={() => <Maintenance />} />

          <Route exact path='/about' render={() => <Maintenance />} />
          <Route exact path='/news' render={() => <Maintenance />} />
          <Route exact path='/documents' render={() => <Documents />} />
          <Route exact path='/newsLetter' render={() => <Maintenance />} />

          <Route path='/propositions' render={() => <Maintenance />} />
          <Route path='/activities' render={() => <Maintenance />} />
          <Route path='/assembly' render={() => <Maintenance />} />
          <Route exact path='/rankings' render={() => <Maintenance />} />
          <Route exact path='/bodies' render={() => <Maintenance />} />
          <Route exact path='/candidacies' render={() => <Maintenance />} />

          { process.env.NODE_ENV !== 'production' && <span className={classes.version}>Stranka NADA Web™ @v{process.env.APP_VERSION}</span> }
        </div>
      </ConnectedRouter>
    )
  }
}

export const Routes = connect(mapState, mapDispatch)(withStyles(styles)(BareRoutes))

import * as React from 'react'
import { Link } from 'react-router-dom'

import * as styles from './NadaLogo.css'

export const NadaLogo: React.SFC<{}> = () => (
  <div className={styles.Wrapper}>
    <Link to='/' className={styles.LogoWrapper}>
      <div className={styles.Logo}>
        <div className={styles.Frame} />
        <div className={styles.Arrow} />
      </div>
      <span className={styles.Text}>NADA</span>
    </Link>
  </div>
)

import { validatorCreatorFactory } from './validatorCreatorFactory'

export const maxLengthCreator = (maxLength: number) =>
  validatorCreatorFactory<number, string, any, any>({
    getValidatorBaseValue: () => maxLength,
    getValidatorErrorMessage: (value) => `Maximum length of field is ${value}`,
    operator: (value) => (input) => value == null || input.length <= value,
  })

export const minLengthCreator = (minLength: number) =>
  validatorCreatorFactory<number, string, any, any>({
    getValidatorBaseValue: () => minLength,
    getValidatorErrorMessage: (value) => `Maximum length of field is ${value}`,
    operator: (value) => (input) => value == null || input.length >= value,
  })

exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1QqvHrwFcjk2{-webkit-box-sizing:border-box!important;box-sizing:border-box!important;color:#4c4c4c!important;height:100%!important}._1-fmuXkJxiY_{height:calc(100% - 150px)!important}._1-fmuXkJxiY_,._23b0SLBw2XJU{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important;-webkit-box-sizing:border-box!important;box-sizing:border-box!important;width:100%!important;padding:0!important;margin:0!important;-webkit-box-orient:horizontal!important;-webkit-box-direction:normal!important;-ms-flex-direction:row!important;flex-direction:row!important;-webkit-box-pack:center!important;-ms-flex-pack:center!important;justify-content:center!important}._1SusxfaDJlJu,._23b0SLBw2XJU{height:100%!important}._1SusxfaDJlJu{min-width:960px!important;max-width:1200px!important}._1jGZsYm5Y8Yj{margin-top:10px!important;width:300px!important;min-width:300px!important;max-width:300px!important}._1rc29teeC3Au{height:100%!important;width:100%!important;margin-left:20px!important}", ""]);

// exports
exports.locals = {
	"Wrapper": "_1QqvHrwFcjk2",
	"LayoutSized": "_1-fmuXkJxiY_",
	"Layout": "_23b0SLBw2XJU",
	"Sized": "_1SusxfaDJlJu",
	"Sidebar": "_1jGZsYm5Y8Yj",
	"Content": "_1rc29teeC3Au"
};
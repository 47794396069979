import { Divider, Icon, List, ListItem, ListItemIcon, Paper } from '@material-ui/core'
import * as React from 'react'

import { NadaLogo } from '../../components/common/NadaLogo'
import { StandardLayout } from '../../components/common/StandardLayout'

import * as styles from './Documents.css'

const Mission = () => (
  <div className={styles.Mission}>
    <h2 className={styles.Title}>Naša Misija:</h2>
    <ul className={styles.List}>
      <li><span>1)</span> Promijeniti fokus društva s 1941. na 2041. godinu</li>
      <li><span>2)</span> Iskorijeniti siromaštvo u Republici Hrvatskoj</li>
      <li><span>3)</span> Trajno i zauvijek riješiti problem nezaposlenosti</li>
      <li><span>4)</span> Temeljita rekonstrukcija pravosudnog sustava</li>
      <li><span>5)</span> Debirokratizacija i deregulacija, u svim granama, na svim razinama</li>
    </ul>
    <h2 className={styles.Footer}>Mjere 2 i 3 – mjere istinske demografske obnove!</h2>
    <div className={styles.Logo}>
      <NadaLogo />
    </div>
  </div>
)


export const Documents = ({ match }: any) => (
  <div>
    <StandardLayout
      match={match}
      sidebar={
        <Paper elevation={2} className={styles.DocumentsWrapper}>
          <List dense>
            <ListItem className={styles.Folder}>
              <ListItemIcon><Icon>folder</Icon></ListItemIcon>
              <div>Općenito</div>
            </ListItem>
            <ListItem button className={styles.File}>
              <a href='/documents/NADA-Statut.pdf'>Statut</a>
            </ListItem>
            <ListItem button className={styles.File}>
              <a href='/documents/NADA-Program.pdf'>Program</a>
            </ListItem>
            <ListItem button className={styles.File}>
              <a href='/documents/Nada ciljevi.pdf'>Ciljevi</a>
            </ListItem>
          </List>
          <Divider />
          <List dense>
            <ListItem className={styles.Folder}>
              <ListItemIcon><Icon>folder</Icon></ListItemIcon>
              <div>Financijska izvješća</div>
            </ListItem>
            <ListItem className={styles.Folder}>
              <List className={styles.Indent} dense>
                <ListItem className={styles.Folder}>
                  <ListItemIcon><Icon>folder</Icon></ListItemIcon>
                  <div>Redovno financiranje</div>
                </ListItem>
                <ListItem button className={styles.File}>
                  <a href='/documents/2019/Bilješke uz Godišnje financijsko izviješće za 2018.pdf'>Bilješke uz Godišnje financijsko izviješće za 2018</a>
                </ListItem>
                <ListItem button className={styles.File}>
                  <a href='/documents/2019/Godišnji financijski izvještaj 2018.pdf'>Godišnji financijski izvještaj 2018</a>
                </ListItem>
                <ListItem button className={styles.File}>
                  <a href='/documents/2019/Izvješće o primljenim donacijama IZ-D 31.12.2018.pdf'>Izvješće o donacijama 2018</a>
                </ListItem>
                <ListItem button className={styles.File}>
                  <a href='/documents/2019/Program rada i financijski plan za 2019.pdf'>Program rada i financijski plan za 2019</a>
                </ListItem>
              </List>
            </ListItem>
            <ListItem className={styles.Folder}>
              <List className={styles.Indent} dense>
                <ListItem className={styles.Folder}>
                  <ListItemIcon><Icon>folder</Icon></ListItemIcon>
                  <div>Izborno financiranje</div>
                </ListItem>
              </List>
            </ListItem>
          </List>
          <Divider />
          <List dense>
            <ListItem className={styles.Folder}>
              <ListItemIcon><Icon>folder</Icon></ListItemIcon>
              <div>Skupština</div>
            </ListItem>
            <ListItem className={styles.Folder}>
              <ListItemIcon><Icon>folder</Icon></ListItemIcon>
              <div>Radna tijela</div>
            </ListItem>
            <List className={styles.Indent} dense>
              <ListItem className={styles.Folder}>
                <ListItemIcon><Icon>folder</Icon></ListItemIcon>
                <div>Upravni odbor</div>
              </ListItem>
              <ListItem button className={styles.File}>
                <a href='/documents/Pravilnik o radu UO.pdf'>Pravilnik o radu UO</a>
              </ListItem>
              <ListItem button className={styles.File}>
                <a href='/documents/Zapisnik sa sjednice 28.12.2017.pdf'>Zapisnik sa sjednice 28.12.2017</a>
              </ListItem>
            </List>
          </List>
          <Divider />
          <List dense>
            <ListItem className={styles.Folder}>
              <ListItemIcon><Icon>folder</Icon></ListItemIcon>
              <div>Pravilnici</div>
            </ListItem>
            <ListItem button className={styles.File}>
              <a href='/documents/Pravilnik o radu UO.pdf'>Pravilnik o radu UO</a>
            </ListItem>
            <ListItem button className={styles.File}>
              <a href='/documents/Pravilnik o članstvu.pdf'>Pravilnik o članstvu</a>
            </ListItem>
            <ListItem button className={styles.File}>
              <a href='/documents/Pravilnik o financiranju stranke.pdf'>Pravilnik o financiranju stranke</a>
            </ListItem>
            <ListItem button className={styles.File}>
              <a href='/documents/Odluka o članarini.pdf'>Odluka o članarini</a>
            </ListItem>
            <ListItem button className={styles.File}>
              <a href='/documents/Odluka o voditelju Registra.pdf'>Odluka o voditelju Registra</a>
            </ListItem>
          </List>
        </Paper>
      }
      content={
        <Paper elevation={2} className={styles.Wrapper}>
          <Mission />
        </Paper>
      }
    />
  </div>
)


import { Grid } from '@material-ui/core'
import * as React from 'react'

import { Header } from './Header'

import * as styles from './StandardLayout.css'

export const StandardLayout = ({ match, sidebar, content }: any) => (
  <div className={styles.Wrapper}>
    <Header match={match} />
    <Grid className={styles.LayoutSized} container justify='center'>
      <Grid className={styles.Sized} item xs={10}>
        { sidebar && content && <div className={styles.Layout}>
          <div className={styles.Sidebar}>{ sidebar }</div>
          <div className={styles.Content}>{ content }</div>
        </div> }

        { !sidebar && content && <div className={styles.Layout}>
          <div className={styles.OnlyContent}>{ content }</div>
        </div> }
      </Grid>
    </Grid>
  </div>
)

StandardLayout.defaultProps = {
  sidebar: null,
}

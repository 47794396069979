exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1ugwYzcKwUOk{color:#fff;padding:8px;-webkit-box-sizing:border-box;box-sizing:border-box}._1ugwYzcKwUOk,._10Qqz9xbOjFU{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}._10Qqz9xbOjFU{height:40px;margin-top:-4px}._10Qqz9xbOjFU:active,._10Qqz9xbOjFU:hover,._10Qqz9xbOjFU:link,._10Qqz9xbOjFU:visited{color:inherit}._2A-Alf4h1t1O{height:38px;width:45px;position:relative}._1yT1HXjoheg6{width:38px;height:38px;background-image:url(/images/okvir_teal.png)}._1yT1HXjoheg6,.hDsnz9qO3H3V{position:absolute;background-position:50%;background-size:contain;background-repeat:no-repeat}.hDsnz9qO3H3V{width:24px;height:24px;left:7px;top:8px;-webkit-transform:rotate(-34deg);transform:rotate(-34deg);background-image:url(/images/strelica.png)}._2NUSN68YxCLP{letter-spacing:.125em;font-size:26px;line-height:36px;font-weight:lighter;color:#9accbc}", ""]);

// exports
exports.locals = {
	"Wrapper": "_1ugwYzcKwUOk",
	"LogoWrapper": "_10Qqz9xbOjFU",
	"Logo": "_2A-Alf4h1t1O",
	"Frame": "_1yT1HXjoheg6",
	"Arrow": "hDsnz9qO3H3V",
	"Text": "_2NUSN68YxCLP"
};
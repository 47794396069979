import axios from 'axios'
import { deserializeUserAuthorizedData, IUserAuthorizedData } from '../common/UserAuthorizedData'
import { serializeUserCredentials, IUserCredentials } from '../common/UserCredentials'

export const login = async (userCredentials: IUserCredentials): Promise<IUserAuthorizedData> => {

  try {
    const response = await axios.request({
      method: 'POST',
      url: '/command/Hope.Login',
      transformRequest: [serializeUserCredentials],
      transformResponse: [deserializeUserAuthorizedData],
      headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json', 'Accept': 'application/json' },
      data: userCredentials,
    })
    return response.data as IUserAuthorizedData
  } catch (error) {
    console.error(error)
    throw error
  }
}

import { createSelector } from 'reselect'

import { IUserState } from '../reducer/user'

export const userStateSelector = (state: any): IUserState =>
  state.user

export const currentUserSelector = createSelector(
  [userStateSelector],
  (userState) => userState.current,
)

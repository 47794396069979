import { FormControlLabel, Switch } from '@material-ui/core'
import * as React from 'react'
import { Field, WrappedFieldProps } from 'redux-form'

import { Validator } from '../../../../lib/form/validation/interfaces'

const renderSwitchField = ({ input, meta, label, ...rest }: WrappedFieldProps) => (
  <FormControlLabel
    label={label}
    control={
      <Switch
        checked={input.checked}
        inputProps={{ id: input.name }}
        {...input}
        {...rest}
      />
    }
  />
)

interface ISwitchFieldProps<F, P> {
  label: string
  name: string
  validate?: Array<Validator<string, F, P>>
  className?: string
}

export class SwitchField<F = {}, P = {}> extends React.PureComponent<ISwitchFieldProps<F, P>> {
  public render() {
    return (
      <Field
        { ...this.props }
        component={renderSwitchField}
      />
    )
  }
}

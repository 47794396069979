import { compose } from 'redux'

import { IUserAuthorizedData } from 'ard/api/typescript/Hope/common/UserAuthorizedData'

import { UserAction, UserActionType } from '../actions/user'

export interface IUserState {
  current: IUserAuthorizedData | null,

  signup: {
    inProgress: boolean,
    error: Error[] | null,
  },

  login: {
    inProgress: boolean,
    error: Error[] | null,
  },

  logout: {
    inProgress: boolean,
    error: Error[] | null,
  }
}

const initial: IUserState = {
  current: null,
  signup: {
    inProgress: false,
    error: null,
  },
  login: {
    inProgress: false,
    error: null,
  },
  logout: {
    inProgress: false,
    error: null,
  },
}

export default (state = initial, action: UserAction) => {
  switch (action.type) {
  case UserActionType.LOGIN_REQUEST:
    return {
      ...state,
      login: {
        ...state.login,
        inProgres: true,
      },
    }

  case UserActionType.LOGIN_SUCCESS:
    return {
      ...state,
      login: {
        ...state.login,
        inProgress: false,
      },
      current: action.payload,
    }

  case UserActionType.LOGIN_FAILURE:
    return {
      login: {
        ...state.login,
        inProgress: false,
        error: action.payload,
      },
    }

  default:
    return state
  }
}

import { Paper } from '@material-ui/core'
import * as React from 'react'

import { NadaLogo } from '../../components/common/NadaLogo'

import * as styles from './Maintenance.css'

interface IMaintenanceProps {
  message?: string
}

export const Maintenance: React.SFC<IMaintenanceProps> = ({ message }) => (
  <div className={styles.Wrapper}>
    <div className={styles.PaperWrapper}>
      <Paper elevation={12} className={styles.Paper}>
        <div className={styles.Logo}>
          <NadaLogo />
        </div>
        <div className={styles.Info}>
          <div className={styles.Spinner}>
            <i className='fa fa-superpowers fa-spin' />
          </div>
          <div className={styles.Message}>
            { message }
          </div>
        </div>
      </Paper>
    </div>
  </div>
)

Maintenance.defaultProps = {
  message: 'Održavanje servera je u tijeku',
}

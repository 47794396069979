
import { Gender } from './Gender'
import { UserStatus } from './UserStatus'

export interface IUserAuthorizedData {
  id: number
  recoveryEmail: string
  oib: string
  personalIdImage?: string
  personalIdNumber?: string
  personalIdIssuer?: string
  phoneNumber: string
  username: string
  email: string
  status: UserStatus
  verifiedAt?: Date
  activatedAt?: Date
  firstName: string
  lastName: string
  about?: string
  county?: string
  city?: string
  area?: string
  address?: string
  gender?: Gender
  nationality?: string
  avatar?: string
  birthday?: Date
}

interface IUserAuthorizedDataProps {
  id: number,
  recoveryEmail: string,
  oib: string,
  personalIdImage?: string,
  personalIdNumber?: string,
  personalIdIssuer?: string,
  phoneNumber: string,
  username: string,
  email: string,
  status?: UserStatus,
  verifiedAt?: Date,
  activatedAt?: Date,
  firstName: string,
  lastName: string,
  about?: string,
  county?: string,
  city?: string,
  area?: string,
  address?: string,
  gender?: Gender,
  nationality?: string,
  avatar?: string,
  birthday?: Date,
}

export const UserAuthorizedData = (props: IUserAuthorizedDataProps): IUserAuthorizedData => {
  return { ...props, status: props.status || UserStatus.Pending }
}

export const createUserAuthorizedDataFromJSON = (obj: any): IUserAuthorizedData => {
  if (obj.id == null) {
    throw new Error('Field id of type number is not present in input')
  }
  const id = obj.id
  if (obj.recoveryEmail == null) {
    throw new Error('Field recoveryEmail of type string is not present in input')
  }
  const recoveryEmail = obj.recoveryEmail
  if (obj.oib == null) {
    throw new Error('Field oib of type string is not present in input')
  }
  const oib = obj.oib
  const personalIdImage = obj.personalIdImage
  const personalIdNumber = obj.personalIdNumber
  const personalIdIssuer = obj.personalIdIssuer
  if (obj.phoneNumber == null) {
    throw new Error('Field phoneNumber of type string is not present in input')
  }
  const phoneNumber = obj.phoneNumber
  if (obj.username == null) {
    throw new Error('Field username of type string is not present in input')
  }
  const username = obj.username
  if (obj.email == null) {
    throw new Error('Field email of type string is not present in input')
  }
  const email = obj.email
  const status = obj.status
  const verifiedAt = obj.verifiedAt
  const activatedAt = obj.activatedAt
  if (obj.firstName == null) {
    throw new Error('Field firstName of type string is not present in input')
  }
  const firstName = obj.firstName
  if (obj.lastName == null) {
    throw new Error('Field lastName of type string is not present in input')
  }
  const lastName = obj.lastName
  const about = obj.about
  const county = obj.county
  const city = obj.city
  const area = obj.area
  const address = obj.address
  const gender = obj.gender
  const nationality = obj.nationality
  const avatar = obj.avatar
  const birthday = obj.birthday

  return UserAuthorizedData({ id, recoveryEmail, oib, personalIdImage, personalIdNumber, personalIdIssuer, phoneNumber, username, email, status, verifiedAt, activatedAt, firstName, lastName, about, county, city, area, address, gender, nationality, avatar, birthday })
}

export const deserializeUserAuthorizedData = (raw: string): IUserAuthorizedData => {
  const parsed = JSON.parse(raw)
  return createUserAuthorizedDataFromJSON(parsed)
}

export const serializeUserAuthorizedData = (obj: IUserAuthorizedData): string => {
  return JSON.stringify(obj)
}

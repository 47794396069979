import { createMuiTheme } from '@material-ui/core/styles'

export const nadaColors = {
  teal: 'rgb(154, 204, 188)',
  darkTeal: 'rgb(140, 188, 173)',
  orange: 'rgb(247, 148, 20)',
  red: 'rgb(244, 67, 54)',
  white: 'rgb(255, 255, 255)',
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: nadaColors.darkTeal,
      main: nadaColors.darkTeal,
      dark: nadaColors.darkTeal,
      contrastText: nadaColors.white,
    },
    secondary: {
      light: nadaColors.teal,
      main: nadaColors.teal,
      dark: nadaColors.teal,
      contrastText: nadaColors.white,
    },
    error: {
      main: nadaColors.red,
    },
  },
})

import { identity } from '../../core/functional'

import {
  GetValidatorBaseValue,
  GetValidatorErrorMessage,
  Operator,
  ParseValue,
  ValidateTrigger,
  ValidatorCreator,
} from './interfaces'

// we treat undefined, null and '' as an absence of values
export const valueIsAbsent = (value: any) =>
  (value == null) || (typeof value === 'string' && value === '')

////////////////////////////////////////////////////////////////////////////////
// Validator creator                                                          //
////////////////////////////////////////////////////////////////////////////////
// Generic types: B = base type, I = input type, F = form type, P = props type
export interface IValidatorCreatorOptions<B, I, F, P, BT = B, IT = I> {
  validate?: ValidateTrigger
  getValidatorErrorMessage: GetValidatorErrorMessage<B, I, F, P> | string
  getValidatorBaseValue?: GetValidatorBaseValue<B, F, P> | B
  getValueFromInput?: ParseValue<IT, I>
  getValueFromBase?: ParseValue<BT, B>
  operator: Operator<B, I>
}
export const validatorCreatorFactory = <B, I, F, P, BT = B, IT = I>({
  validate = ValidateTrigger.IfInputIsSet,
  getValidatorErrorMessage,
  getValidatorBaseValue,
  getValueFromInput = (identity as ParseValue<IT, I>),
  getValueFromBase = (identity as ParseValue<BT, B>),
  operator,
}: IValidatorCreatorOptions<B, I, F, P, BT, IT>): ValidatorCreator<B, I, F, P, BT, IT> =>
  (creatorProps = {}) =>
    (inputValue, allValues, props, name) => {
      const {
        validateOverride,
        getValidatorBaseValueOverride,
        getValidatorErrorMessageOverride,
        getValueFromInputOverride,
        getValueFromBaseOverride,
      } = creatorProps

      const validateIf = validateOverride || validate
      const getBaseValue = getValidatorBaseValueOverride || getValidatorBaseValue
      const parseInputValue: ParseValue<IT, I> = getValueFromInputOverride || getValueFromInput
      const parseBaseValue: ParseValue<BT, B> = getValueFromBaseOverride || getValueFromBase

      const baseValue: BT = typeof getBaseValue === 'function'
        ? (getBaseValue as any)(allValues, props, name)
        : getBaseValue

      if (
        ((validateIf === ValidateTrigger.IfInputIsSet) && (valueIsAbsent(inputValue))) ||
        ((validateIf === ValidateTrigger.IfBaseIsSet) && (valueIsAbsent(baseValue))) ||
        ((validateIf === ValidateTrigger.IfInputAndBaseAreSet) && (valueIsAbsent(inputValue) || valueIsAbsent(baseValue)))
      ) {
        return undefined
      }

      const parsedBaseValue = parseBaseValue(baseValue)
      const parsedInputValue = parseInputValue((inputValue as any) as IT)

      const getErrorMessage = getValidatorErrorMessageOverride || getValidatorErrorMessage
      const message = typeof getErrorMessage === 'function'
        ? getErrorMessage(parsedBaseValue, parsedInputValue, allValues, props, name as string)
        : getErrorMessage

      return operator(parsedBaseValue)(parsedInputValue)
        ? undefined
        : message
    }

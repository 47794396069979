import {
  EqOperator,
  NumOperator,
  Ord,
  OrdAlphaOperator,
  OrdOperator,
  RequireableOperator,
} from './interfaces'

// Basic Operators
export const required: RequireableOperator = () => (inputValue) =>
  (inputValue != null && inputValue !== '' && (Array.isArray(inputValue) ? inputValue.length > 0 : true))

export const eq: EqOperator = (baseValue) => (inputValue) => baseValue === inputValue

export type CompareResult = -1 | 0 | 1

export type AlphaComparator =
  & ((a: string, b: string) => CompareResult)

export type Comparator =
  & ((a: number, b: number) => CompareResult)
  & ((a: string, b: string) => CompareResult)
  & ((a: Ord, b: Ord) => CompareResult)

export const compare: Comparator = (a: Ord, b: Ord) => {
  const src = typeof a === 'string' ? parseFloat(a) : a
  const dst = typeof b === 'string' ? parseFloat(b) : b

  if (src > dst) {
    return 1
  } else if (src < dst) {
    return -1
  } else {
    return 0
  }
}

export const compareAlpha: AlphaComparator = (a, b) => {
  if (a > b) {
    return 1
  } else if (a < b) {
    return -1
  } else {
    return 0
  }
}

export const gt: OrdOperator = (baseValue) => (inputValue) => compare(inputValue, baseValue) === 1
export const gte: OrdOperator = (baseValue) => (inputValue) => compare(inputValue, baseValue) >= 0
export const lt: OrdOperator = (baseValue) => (inputValue) => compare(inputValue, baseValue) === -1
export const lte: OrdOperator = (baseValue) => (inputValue) => compare(inputValue, baseValue) <= 0

export const gtAlpha: OrdAlphaOperator = (baseValue) => (inputValue) => compareAlpha(inputValue, baseValue) === 1
export const gteAlpha: OrdAlphaOperator = (baseValue) => (inputValue) => compareAlpha(inputValue, baseValue) >= 0
export const ltAlpha: OrdAlphaOperator = (baseValue) => (inputValue) => compareAlpha(inputValue, baseValue) === -1
export const lteAlpha: OrdAlphaOperator = (baseValue) => (inputValue) => compareAlpha(inputValue, baseValue) <= 0

export const multipleOfFactor: NumOperator = (baseValue) => (inputValue) => (inputValue % baseValue) === 0

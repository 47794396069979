import { Paper } from '@material-ui/core'
import * as React from 'react'

import { NadaLinkLogo } from './NadaLinkLogo'
import * as styles from './Navbar.css'

export class Navbar extends React.PureComponent<{}> {

  public render() {
    const { children } = this.props
    return (
      <Paper className={styles.Navbar} elevation={4}>
        <div className={styles.Half}>
          <NadaLinkLogo />
        </div>

        <div className={styles.Half}>
          { children }
        </div>
      </Paper>
    )
  }
}

import 'font-awesome/css/font-awesome.css'
import * as React from 'react'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import ws from 'web/ws'

import App from './App'
import store from './store'

ws()

// debug helpers
if (process.env.ENV !== 'production') {
  const iso7064 = (nums: number[]) =>
    (11 - nums.reduce((memo, rest) => ((((rest + memo) % 10) || 10) * 2) % 11, 10))

  const zeroToNine = () =>
    Math.floor(Math.random() * 10)

  const randomOib = () => {
    const nums = 'xxxxxxxxxx'.split('').map(() => zeroToNine())
    return `${nums.join('')}${iso7064(nums)}`
  }

  const randomPhoneNumber = () =>
    `09${
      [1, 2, 5, 7, 8, 9][Math.floor(Math.random() * 6)]
    }/${
      'xxx'.split('').map(zeroToNine).join('')
    }-${
      'xxxx'.split('').map(zeroToNine).join('')
    }`;

  (window as any).randomPhoneNumber = randomPhoneNumber;
  (window as any).randomOib = randomOib
}

const doRender = (App: any) =>
  render(
    <AppContainer>
      <Provider store={store}>
        <App />
      </Provider>
    </AppContainer>,
    window.document.getElementById('react-root'),
  )

doRender(App)

if ((module as any).hot) {
  (module as any).hot.accept('./App', () => doRender(require('./App').default))
}

import { call, put, takeLatest } from 'redux-saga/effects'

import { login } from '../../../../ard/api/typescript/Hope/service/Login'

import { IUserAuthorizedData } from 'ard/api/typescript/Hope/common/UserAuthorizedData'
import { userLoginFailure, userLoginRequest, userLoginSuccess, UserActionType } from '../actions/user'


function * loginWatcher() {
  yield takeLatest(UserActionType.LOGIN_REQUEST, loginUserWorker)
}

function * loginUserWorker(action: ReturnType<typeof userLoginRequest>) {
  try {
    const result: IUserAuthorizedData = yield call(login, action.payload)
    yield put(userLoginSuccess(result))
  } catch (error) {
    console.error(error)
    yield put(userLoginFailure(error))
  }
}

export const sagas = [
  loginWatcher,
]

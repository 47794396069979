import { identity } from './func'
import { fn1,  fn10, fn11, fn12, fn2, fn3, fn4, fn5, fn6, fn7, fn8, fn9 } from './typeUtils'

type Compose =
  & (() => (<r>(x: r) => r))
  & (<a0, r>(m0: fn1<a0, r>) => fn1<a0, r>)

  & (<a0, a1, r>(m1: fn1<a1, r>, m0: fn1<a0, a1>) => fn1<a0, r>)
  & (<a0, a1, r>(m0: fn2<a0, a1, r>) => fn2<a0, a1, r>)

  & (<a0, a1, a2, r>(m2: fn1<a2, r>, m1: fn1<a1, a2>, m0: fn1<a0, a1>) => fn1<a0, r>)
  & (<a0, a1, a2, r>(m1: fn1<a2, r>, m0: fn2<a0, a1, a2>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, r>(m0: fn3<a0, a1, a2, r>) => fn3<a0, a1, a2, r>)

  & (<a0, a1, a2, a3, r>(m3: fn1<a3, r>, m2: fn1<a2, a3>, m1: fn1<a1, a2>, m0: fn1<a0, a1>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, r>(m2: fn1<a3, r>, m1: fn1<a2, a3>, m0: fn2<a0, a1, a2>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, r>(m1: fn1<a3, r>, m0: fn3<a0, a1, a2, a3>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, r>(m0: fn4<a0, a1, a2, a3, r>) => fn4<a0, a1, a2, a3, r>)

  & (<a0, a1, a2, a3, a4, r>(m4: fn1<a4, r>, m3: fn1<a3, a4>, m2: fn1<a2, a3>, m1: fn1<a1, a2>, m0: fn1<a0, a1>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, r>(m3: fn1<a4, r>, m2: fn1<a3, a4>, m1: fn1<a2, a3>, m0: fn2<a0, a1, a2>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, r>(m2: fn1<a4, r>, m1: fn1<a3, a4>, m0: fn3<a0, a1, a2, a3>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, r>(m1: fn1<a4, r>, m0: fn4<a0, a1, a2, a3, a4>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, r>(m0: fn5<a0, a1, a2, a3, a4, r>) => fn5<a0, a1, a2, a3, a4, r>)

  & (<a0, a1, a2, a3, a4, a5, r>(m5: fn1<a5, r>, m4: fn1<a4, a5>, m3: fn1<a3, a4>, m2: fn1<a2, a3>, m1: fn1<a1, a2>, m0: fn1<a0, a1>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, a5, r>(m4: fn1<a5, r>, m3: fn1<a4, a5>, m2: fn1<a3, a4>, m1: fn1<a2, a3>, m0: fn2<a0, a1, a2>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, a5, r>(m3: fn1<a5, r>, m2: fn1<a4, a5>, m1: fn1<a3, a4>, m0: fn3<a0, a1, a2, a3>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, a5, r>(m2: fn1<a5, r>, m1: fn1<a4, a5>, m0: fn4<a0, a1, a2, a3, a4>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, a5, r>(m1: fn1<a5, r>, m0: fn5<a0, a1, a2, a3, a4, a5>) => fn5<a0, a1, a2, a3, a4, r>)
  & (<a0, a1, a2, a3, a4, a5, r>(m0: fn6<a0, a1, a2, a3, a4, a5, r>) => fn6<a0, a1, a2, a3, a4, a5, r>)

  & (<a0, a1, a2, a3, a4, a5, a6, r>(m6: fn1<a6, r>, m5: fn1<a5, a6>, m4: fn1<a4, a5>, m3: fn1<a3, a4>, m2: fn1<a2, a3>, m1: fn1<a1, a2>, m0: fn1<a0, a1>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, r>(m5: fn1<a6, r>, m4: fn1<a5, a6>, m3: fn1<a4, a5>, m2: fn1<a3, a4>, m1: fn1<a2, a3>, m0: fn2<a0, a1, a2>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, r>(m4: fn1<a6, r>, m3: fn1<a5, a6>, m2: fn1<a4, a5>, m1: fn1<a3, a4>, m0: fn3<a0, a1, a2, a3>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, r>(m3: fn1<a6, r>, m2: fn1<a5, a6>, m1: fn1<a4, a5>, m0: fn4<a0, a1, a2, a3, a4>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, r>(m2: fn1<a6, r>, m1: fn1<a5, a6>, m0: fn5<a0, a1, a2, a3, a4, a5>) => fn5<a0, a1, a2, a3, a4, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, r>(m1: fn1<a6, r>, m0: fn6<a0, a1, a2, a3, a4, a5, a6>) => fn6<a0, a1, a2, a3, a4, a5, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, r>(m0: fn7<a0, a1, a2, a3, a4, a5, a6, r>) => fn7<a0, a1, a2, a3, a4, a5, a6, r>)

  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m7: fn1<a7, r>, m6: fn1<a6, a7>, m5: fn1<a5, a6>, m4: fn1<a4, a5>, m3: fn1<a3, a4>, m2: fn1<a2, a3>, m1: fn1<a1, a2>, m0: fn1<a0, a1>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m6: fn1<a7, r>, m5: fn1<a6, a7>, m4: fn1<a5, a6>, m3: fn1<a4, a5>, m2: fn1<a3, a4>, m1: fn1<a2, a3>, m0: fn2<a0, a1, a2>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m5: fn1<a7, r>, m4: fn1<a6, a7>, m3: fn1<a5, a6>, m2: fn1<a4, a5>, m1: fn1<a3, a4>, m0: fn3<a0, a1, a2, a3>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m4: fn1<a7, r>, m3: fn1<a6, a7>, m2: fn1<a5, a6>, m1: fn1<a4, a5>, m0: fn4<a0, a1, a2, a3, a4>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m3: fn1<a7, r>, m2: fn1<a6, a7>, m1: fn1<a5, a6>, m0: fn5<a0, a1, a2, a3, a4, a5>) => fn5<a0, a1, a2, a3, a4, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m2: fn1<a7, r>, m1: fn1<a6, a7>, m0: fn6<a0, a1, a2, a3, a4, a5, a6>) => fn6<a0, a1, a2, a3, a4, a5, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m1: fn1<a7, r>, m0: fn7<a0, a1, a2, a3, a4, a5, a6, a7>) => fn7<a0, a1, a2, a3, a4, a5, a6, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m0: fn8<a0, a1, a2, a3, a4, a5, a6, a7, r>) => fn8<a0, a1, a2, a3, a4, a5, a6, a7, r>)

  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m8: fn1<a8, r>, m7: fn1<a7, a8>, m6: fn1<a6, a7>, m5: fn1<a5, a6>, m4: fn1<a4, a5>, m3: fn1<a3, a4>, m2: fn1<a2, a3>, m1: fn1<a1, a2>, m0: fn1<a0, a1>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m7: fn1<a8, r>, m6: fn1<a7, a8>, m5: fn1<a6, a7>, m4: fn1<a5, a6>, m3: fn1<a4, a5>, m2: fn1<a3, a4>, m1: fn1<a2, a3>, m0: fn2<a0, a1, a2>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m6: fn1<a8, r>, m5: fn1<a7, a8>, m4: fn1<a6, a7>, m3: fn1<a5, a6>, m2: fn1<a4, a5>, m1: fn1<a3, a4>, m0: fn3<a0, a1, a2, a3>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m5: fn1<a8, r>, m4: fn1<a7, a8>, m3: fn1<a6, a7>, m2: fn1<a5, a6>, m1: fn1<a4, a5>, m0: fn4<a0, a1, a2, a3, a4>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m4: fn1<a8, r>, m3: fn1<a7, a8>, m2: fn1<a6, a7>, m1: fn1<a5, a6>, m0: fn5<a0, a1, a2, a3, a4, a5>) => fn5<a0, a1, a2, a3, a4, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m3: fn1<a8, r>, m2: fn1<a7, a8>, m1: fn1<a6, a7>, m0: fn6<a0, a1, a2, a3, a4, a5, a6>) => fn6<a0, a1, a2, a3, a4, a5, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m2: fn1<a8, r>, m1: fn1<a7, a8>, m0: fn7<a0, a1, a2, a3, a4, a5, a6, a7>) => fn7<a0, a1, a2, a3, a4, a5, a6, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m1: fn1<a8, r>, m0: fn8<a0, a1, a2, a3, a4, a5, a6, a7, a8>) => fn8<a0, a1, a2, a3, a4, a5, a6, a7, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m0: fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>) => fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>)

  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m9: fn1<a9, r>, m8: fn1<a8, a9>, m7: fn1<a7, a8>, m6: fn1<a6, a7>, m5: fn1<a5, a6>, m4: fn1<a4, a5>, m3: fn1<a3, a4>, m2: fn1<a2, a3>, m1: fn1<a1, a2>, m0: fn1<a0, a1>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m8: fn1<a9, r>, m7: fn1<a8, a9>, m6: fn1<a7, a8>, m5: fn1<a6, a7>, m4: fn1<a5, a6>, m3: fn1<a4, a5>, m2: fn1<a3, a4>, m1: fn1<a2, a3>, m0: fn2<a0, a1, a2>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m7: fn1<a9, r>, m6: fn1<a8, a9>, m5: fn1<a7, a8>, m4: fn1<a6, a7>, m3: fn1<a5, a6>, m2: fn1<a4, a5>, m1: fn1<a3, a4>, m0: fn3<a0, a1, a2, a3>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m6: fn1<a9, r>, m5: fn1<a8, a9>, m4: fn1<a7, a8>, m3: fn1<a6, a7>, m2: fn1<a5, a6>, m1: fn1<a4, a5>, m0: fn4<a0, a1, a2, a3, a4>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m5: fn1<a9, r>, m4: fn1<a8, a9>, m3: fn1<a7, a8>, m2: fn1<a6, a7>, m1: fn1<a5, a6>, m0: fn5<a0, a1, a2, a3, a4, a5>) => fn5<a0, a1, a2, a3, a4, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m4: fn1<a9, r>, m3: fn1<a8, a9>, m2: fn1<a7, a8>, m1: fn1<a6, a7>, m0: fn6<a0, a1, a2, a3, a4, a5, a6>) => fn6<a0, a1, a2, a3, a4, a5, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m3: fn1<a9, r>, m2: fn1<a8, a9>, m1: fn1<a7, a8>, m0: fn7<a0, a1, a2, a3, a4, a5, a6, a7>) => fn7<a0, a1, a2, a3, a4, a5, a6, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m2: fn1<a9, r>, m1: fn1<a8, a9>, m0: fn8<a0, a1, a2, a3, a4, a5, a6, a7, a8>) => fn8<a0, a1, a2, a3, a4, a5, a6, a7, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m1: fn1<a9, r>, m0: fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9>) => fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m0: fn10<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>) => fn10<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>)

  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m10: fn1<a10, r>, m9: fn1<a9, a10>, m8: fn1<a8, a9>, m7: fn1<a7, a8>, m6: fn1<a6, a7>, m5: fn1<a5, a6>, m4: fn1<a4, a5>, m3: fn1<a3, a4>, m2: fn1<a2, a3>, m1: fn1<a1, a2>, m0: fn1<a0, a1>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m9: fn1<a10, r>, m8: fn1<a9, a10>, m7: fn1<a8, a9>, m6: fn1<a7, a8>, m5: fn1<a6, a7>, m4: fn1<a5, a6>, m3: fn1<a4, a5>, m2: fn1<a3, a4>, m1: fn1<a2, a3>, m0: fn2<a0, a1, a2>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m8: fn1<a10, r>, m7: fn1<a9, a10>, m6: fn1<a8, a9>, m5: fn1<a7, a8>, m4: fn1<a6, a7>, m3: fn1<a5, a6>, m2: fn1<a4, a5>, m1: fn1<a3, a4>, m0: fn3<a0, a1, a2, a3>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m7: fn1<a10, r>, m6: fn1<a9, a10>, m5: fn1<a8, a9>, m4: fn1<a7, a8>, m3: fn1<a6, a7>, m2: fn1<a5, a6>, m1: fn1<a4, a5>, m0: fn4<a0, a1, a2, a3, a4>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m6: fn1<a10, r>, m5: fn1<a9, a10>, m4: fn1<a8, a9>, m3: fn1<a7, a8>, m2: fn1<a6, a7>, m1: fn1<a5, a6>, m0: fn5<a0, a1, a2, a3, a4, a5>) => fn5<a0, a1, a2, a3, a4, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m5: fn1<a10, r>, m4: fn1<a9, a10>, m3: fn1<a8, a9>, m2: fn1<a7, a8>, m1: fn1<a6, a7>, m0: fn6<a0, a1, a2, a3, a4, a5, a6>) => fn6<a0, a1, a2, a3, a4, a5, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m4: fn1<a10, r>, m3: fn1<a9, a10>, m2: fn1<a8, a9>, m1: fn1<a7, a8>, m0: fn7<a0, a1, a2, a3, a4, a5, a6, a7>) => fn7<a0, a1, a2, a3, a4, a5, a6, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m3: fn1<a10, r>, m2: fn1<a9, a10>, m1: fn1<a8, a9>, m0: fn8<a0, a1, a2, a3, a4, a5, a6, a7, a8>) => fn8<a0, a1, a2, a3, a4, a5, a6, a7, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m2: fn1<a10, r>, m1: fn1<a9, a10>, m0: fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9>) => fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m1: fn1<a10, r>, m0: fn10<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10>) => fn10<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m0: fn11<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>) => fn11<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>)

  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m11: fn1<a11, r>, m10: fn1<a10, a11>, m9: fn1<a9, a10>, m8: fn1<a8, a9>, m7: fn1<a7, a8>, m6: fn1<a6, a7>, m5: fn1<a5, a6>, m4: fn1<a4, a5>, m3: fn1<a3, a4>, m2: fn1<a2, a3>, m1: fn1<a1, a2>, m0: fn1<a0, a1>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m10: fn1<a11, r>, m9: fn1<a10, a11>, m8: fn1<a9, a10>, m7: fn1<a8, a9>, m6: fn1<a7, a8>, m5: fn1<a6, a7>, m4: fn1<a5, a6>, m3: fn1<a4, a5>, m2: fn1<a3, a4>, m1: fn1<a2, a3>, m0: fn2<a0, a1, a2>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m9: fn1<a11, r>, m8: fn1<a10, a11>, m7: fn1<a9, a10>, m6: fn1<a8, a9>, m5: fn1<a7, a8>, m4: fn1<a6, a7>, m3: fn1<a5, a6>, m2: fn1<a4, a5>, m1: fn1<a3, a4>, m0: fn3<a0, a1, a2, a3>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m8: fn1<a11, r>, m7: fn1<a10, a11>, m6: fn1<a9, a10>, m5: fn1<a8, a9>, m4: fn1<a7, a8>, m3: fn1<a6, a7>, m2: fn1<a5, a6>, m1: fn1<a4, a5>, m0: fn4<a0, a1, a2, a3, a4>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m7: fn1<a11, r>, m6: fn1<a10, a11>, m5: fn1<a9, a10>, m4: fn1<a8, a9>, m3: fn1<a7, a8>, m2: fn1<a6, a7>, m1: fn1<a5, a6>, m0: fn5<a0, a1, a2, a3, a4, a5>) => fn5<a0, a1, a2, a3, a4, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m6: fn1<a11, r>, m5: fn1<a10, a11>, m4: fn1<a9, a10>, m3: fn1<a8, a9>, m2: fn1<a7, a8>, m1: fn1<a6, a7>, m0: fn6<a0, a1, a2, a3, a4, a5, a6>) => fn6<a0, a1, a2, a3, a4, a5, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m5: fn1<a11, r>, m4: fn1<a10, a11>, m3: fn1<a9, a10>, m2: fn1<a8, a9>, m1: fn1<a7, a8>, m0: fn7<a0, a1, a2, a3, a4, a5, a6, a7>) => fn7<a0, a1, a2, a3, a4, a5, a6, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m4: fn1<a11, r>, m3: fn1<a10, a11>, m2: fn1<a9, a10>, m1: fn1<a8, a9>, m0: fn8<a0, a1, a2, a3, a4, a5, a6, a7, a8>) => fn8<a0, a1, a2, a3, a4, a5, a6, a7, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m3: fn1<a11, r>, m2: fn1<a10, a11>, m1: fn1<a9, a10>, m0: fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9>) => fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m2: fn1<a11, r>, m1: fn1<a10, a11>, m0: fn10<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10>) => fn10<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m1: fn1<a11, r>, m0: fn11<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11>) => fn11<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m0: fn12<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>) => fn12<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>)

const composeImpl: any = (...f: any[]) => {
  if (f.length === 0) {
    return identity
  } else if (f.length === 1) {
    return f[0]
  } else {
    return f.reduce((a, b) => (...args: any[]) => a(b(...args)))
  }
}
export const compose: Compose = composeImpl


export interface IUserCredentials {
  username: string
  password: string
}

interface IUserCredentialsProps {
  username: string,
  password: string,
}

export const UserCredentials = (props: IUserCredentialsProps): IUserCredentials => {
  return { ...props }
}

export const createUserCredentialsFromJSON = (obj: any): IUserCredentials => {
  if (obj.username == null) {
    throw new Error('Field username of type string is not present in input')
  }
  const username = obj.username
  if (obj.password == null) {
    throw new Error('Field password of type string is not present in input')
  }
  const password = obj.password

  return UserCredentials({ username, password })
}

export const deserializeUserCredentials = (raw: string): IUserCredentials => {
  const parsed = JSON.parse(raw)
  return createUserCredentialsFromJSON(parsed)
}

export const serializeUserCredentials = (obj: IUserCredentials): string => {
  return JSON.stringify(obj)
}

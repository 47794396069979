import { Button, Checkbox, FormControlLabel, Grid, Icon, Input, Paper } from '@material-ui/core'
import classnames from 'classnames'
import { push } from 'connected-react-router'
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'

import { compose } from '../../../../lib/core/functional'

import { NadaLinkLogo } from './NadaLinkLogo'

// import { logout } from '../state/action/user'
// import { selectUser } from '../state/selector'

import * as styles from './Header.css'


const mapState = () => ({})

const mapDispatch = (dispatch: any) => ({
  navigateTo: (url: string) => () => dispatch(push(url)),
  logout: () =>
    dispatch({} /* logout() */),
})

const isOnPage = (match: any, route: string) =>
  match.url === route

const UPPER_LINKS = [
  ['/about', 'O Nama'],
  ['/news', 'Novosti'],
  ['/documents', 'Dokumenti'],
  ['/newsletter', 'Obavijesti Me'],
]

const LOWER_LINKS = [
  ['/propositions', 'Prijedlozi'],
  ['/activities', 'Akcije'],
  ['/rankings', 'Rang Liste'],
  ['/bodies', 'Radna Tijela'],
  ['/candidacies', 'Kandidature'],
]

const HeaderC = ({ match, user, logout, navigateTo }: any) => (
  <Paper className={styles.Wrapper} elevation={2}>
    <Grid className={styles.NormalizeWidth} container justify='center' spacing={8}>
      <Grid className={styles.FlexWrapper} item xs={10}>
        <Grid className={styles.Sized} container justify='center'>
          <Grid item xs={12} className={styles.Tall}>
            <Grid container justify='center'>
              <Grid item xs={3} className={styles.LogoWrapper}>
                <NadaLinkLogo />
              </Grid>
              <Grid className={styles.LinksWrapper} item xs={5}>
                { UPPER_LINKS.map((route) =>
                  <Link
                    key={route[0]}
                    className={classnames(styles.Link, isOnPage(match, route[0]) && styles.ActiveLink)}
                    to={route[0]}
                  >
                    {route[1]}
                  </Link>) }
              </Grid>
              { user
                ? <Grid className={styles.ButtonsWrapper} item xs={4}>
                  <Button onClick={navigateTo('/profile')} className={styles.Button}><i className={classnames(styles.ButtonIcon, 'fa fa-cog')} /> Postavke</Button>
                  <Button onClick={logout} className={styles.Button}><i className={classnames(styles.ButtonIcon, 'fa fa-sign-out')} /> Odjava</Button>
                </Grid>
                : <Grid className={styles.ButtonsWrapper} item xs={4}>
                  <Button onClick={navigateTo('/login')} className={styles.Button}><i className={classnames(styles.ButtonIcon, 'fa fa-sign-in')} /> Prijava</Button>
                </Grid> }
            </Grid>
          </Grid>

          <Grid item xs={12} className={styles.Short}>
            <div className={styles.Line} />
            <div className={styles.Text}>obliti privatorum publica curate</div>
            <div className={styles.Line} />
          </Grid>

          <Grid item xs={12} className={styles.Tall}>
            <Grid container justify='center'>
              <Grid className={styles.LinksWrapper} item xs={6}>
                { LOWER_LINKS.map((route) =>
                  <Link
                    key={route[0]}
                    className={classnames(styles.Link, isOnPage(match, route[0]) && styles.ActiveLink)}
                    to={route[0]}
                  >
                    {route[1]}
                  </Link>) }
              </Grid>
              <Grid item xs={6}>
                { LOWER_LINKS.map((route) => route[0]).some((route) => match.url.indexOf(route) !== -1)
                  ? <div className={styles.LowerLinks}>
                    <div className={styles.LowerLinksControl}>
                      <Input className={styles.Input} classes={{ underline: styles.Underline, inkbar: styles.Inkbar }} placeholder='Pretraži' />
                      <Icon className={styles.SearchIcon}>search</Icon>
                    </div>
                    <div className={styles.LowerLinksControl}>
                      <FormControlLabel
                        control={<Checkbox checked classes={{ default: styles.Label, checked: styles.Label }} />}
                        label={'Kompaktno'}
                        classes={{ label: styles.Label }}
                      />
                    </div>
                    <div className={styles.LowerLinksControl}>
                      <FormControlLabel
                        control={<Checkbox checked={false} classes={{ default: styles.Label, checked: styles.Label }} />}
                        label={'Arhivirani'}
                        classes={{ label: styles.Label }}
                      />
                    </div>
                    <div className={styles.LowerLinksControl}>
                      <Button
                        disabled={!user}
                        className={classnames(styles.AddButton, !user && styles.Disabled)}
                        onClick={navigateTo(`${match.url}/new`)}
                      >
                        <Icon>add</Icon>Novo
                      </Button>
                    </div>
                  </div>
                  : null }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Paper>
)

HeaderC.defaultProps = {
  user: null,
}

export const Header = compose(
  connect(mapState, mapDispatch) as any,
  withRouter as any,
)(HeaderC) as React.SFC<{match: string}>

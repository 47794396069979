exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".QCXRZ-V6dZth{width:100%;height:100%;-ms-flex-pack:center;-ms-flex-line-pack:center;align-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}._3mk7Pp80FHsq,.QCXRZ-V6dZth{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;justify-content:center}._3mk7Pp80FHsq{width:640px;height:480px;-ms-flex-pack:center;-ms-flex-line-pack:start;align-content:flex-start;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start}._3Aom13RHGl8o{-webkit-box-flex:1;-ms-flex:1 1;flex:1 1;height:320px;padding:40px;border-radius:6px!important;-webkit-box-sizing:border-box;box-sizing:border-box}.rjFzkapefXSa{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}._2pvCQdW0bvag,.rjFzkapefXSa{-webkit-box-sizing:border-box;box-sizing:border-box;padding:30px}._2pvCQdW0bvag{font-size:24px}._1u3DB9Q3tyZh{-webkit-box-sizing:border-box;box-sizing:border-box;padding:30px}", ""]);

// exports
exports.locals = {
	"Wrapper": "QCXRZ-V6dZth",
	"PaperWrapper": "_3mk7Pp80FHsq",
	"Paper": "_3Aom13RHGl8o",
	"Info": "rjFzkapefXSa",
	"Spinner": "_2pvCQdW0bvag",
	"Message": "_1u3DB9Q3tyZh"
};
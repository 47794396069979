import { TextField as TextFieldInput } from '@material-ui/core'
import * as React from 'react'
import { Field, WrappedFieldProps } from 'redux-form'

import { Validator } from '../../../../lib/form/validation/interfaces'

const renderInputField = ({ input, meta, label, ...rest }: WrappedFieldProps) => (
  <TextFieldInput
    error={Boolean(!meta.pristine && meta.error)}
    id={input.name}
    label={label}
    helperText={!meta.pristine && meta.error}
    margin='normal'
    fullWidth
    {...input}
    {...rest}
  />
)

interface IInputFieldProps<F, P> {
  label: string
  name: string
  placeholder: string
  validate?: Array<Validator<string, F, P>>
  type?: 'password' | 'text' | 'number'
  className?: string
}

export class InputField<F, P = {}> extends React.PureComponent<IInputFieldProps<F, P>> {
  static defaultProps = {
    type: 'text',
  }

  public render() {
    return (
      <Field
        { ...this.props }
        component={renderInputField}
      />
    )
  }
}

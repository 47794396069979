import * as React from 'react'
import { Link } from 'react-router-dom'

import * as styles from './NadaLinkLogo.css'

export const NadaLinkLogo: React.SFC<{}> = () => (
  <div className={styles.wrapper}>
    <Link to='/' className={styles.logoWrapper}>
      <div className={styles.logo}>
        <div className={styles.frame} />
        <div className={styles.arrow} />
      </div>
      <span className={styles.text}>NADA</span>
    </Link>
  </div>
)

exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._21vSFJ_wLPyw{margin-top:10px!important;padding:60px;fontSize:24px}._3BOw5JFmB6kl,._21vSFJ_wLPyw{-webkit-box-sizing:border-box;box-sizing:border-box;width:100%;text-align:center;color:#4c4c4c;font-weight:lighter}._3BOw5JFmB6kl{padding:0 16px 8px;font-Size:14px}._2MfeJ0XSnRY7,.t2iXLXX8QIZr{padding-left:0;padding-right:0}._2MfeJ0XSnRY7{margin-left:16px!important}.DFX_H9KBNpBE{text-align:left}._38nLyIMWZTCd{font-weight:bolder;text-transform:uppercase;padding:12px 0 24px}._1NZ-VXaSni3Q{font-size:18}._1NZ-VXaSni3Q>li{padding:6px 0}._1NZ-VXaSni3Q>li>span{padding:0 12px 0 0}._1GcKwmy5LLuX{padding-left:20px}.woUAae2Ry3rf{padding:96px 12px 24px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.yWuL2PZ8qmKZ{padding:24px 0 0;text-transform:uppercase;font-weight:bolder}", ""]);

// exports
exports.locals = {
	"Wrapper": "_21vSFJ_wLPyw",
	"DocumentsWrapper": "_3BOw5JFmB6kl",
	"File": "_2MfeJ0XSnRY7",
	"Folder": "t2iXLXX8QIZr",
	"Mission": "DFX_H9KBNpBE",
	"Title": "_38nLyIMWZTCd",
	"List": "_1NZ-VXaSni3Q",
	"Indent": "_1GcKwmy5LLuX",
	"Logo": "woUAae2Ry3rf",
	"Footer": "yWuL2PZ8qmKZ"
};
import { MuiThemeProvider } from '@material-ui/core'
import * as M from 'moment'
import * as React from 'react'

import 'font-awesome/css/font-awesome.css'

import { Routes } from './Routes'
import { theme } from './config/theme'

import './App.css'

M.locale('hr')

const App = () => (
  <MuiThemeProvider theme={theme} >
    <Routes />
  </MuiThemeProvider>
)

export default App

import * as io from 'socket.io-client'

const echo = (io: SocketIOClientStatic) => {
  const echo = io({ transports: ['websocket'] })

  echo.on('echo', (message: string) => {
    console.debug(message)
  })
}

export default () => {
  echo(io)
}


import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import createReducer from './reducer'
import { rootSaga } from './sagas'

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

const configureStore = (initialState?: any) => {
  const store = createStore(
    connectRouter(history)(createReducer()),
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
      ),
    ),
    initialState,
  );
  (store as any).injectedReducers = {}
  return store
}

const injectReducer = (store: any, name: any, reducer: any) => {
  store.injectedReducers[name] = reducer
  store.replaceReducer(createReducer(store.injectedReducers))
}

const ejectReducer = (store: any, name: any) => {
  delete store.injectReducers[name]
  store.replaceReducer(createReducer(store.injectedReducers))
}

// TODO: type the complete state
const store = configureStore()

// run the initial sagas
sagaMiddleware.run(rootSaga)

export default store
export { history, injectReducer, ejectReducer, sagaMiddleware }

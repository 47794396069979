import { Button, Grid, Paper } from '@material-ui/core'
import { push } from 'connected-react-router'
import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import * as classNames from 'classnames'
// import { withStyles } from 'material-ui/styles'

import { Link } from 'react-router-dom'

import { userLogoutRequest } from 'web/store/actions/user'
import { currentUserSelector } from 'web/store/selectors/user'

import { NadaLinkLogo } from 'web/components/common/NadaLinkLogo'

import * as styles from './Home.css'
import {
  IHomeMapDispatchProps,
  IHomeMapStateProps,
  IHomeProps,
  IHomePublicProps,
  IHomeState,
} from './interfaces'


const mapState = createStructuredSelector<any, any, IHomeMapStateProps>({
  currentUser: currentUserSelector,
})

const mapDispatch: IHomeMapDispatchProps = {
  push,
  userLogoutRequest,
}

class HomeC extends React.PureComponent<IHomeProps, IHomeState> {
  public state = {
    angle: 0,
  }

  private arrow: HTMLDivElement | null = null

  render() {
    const { currentUser, push, userLogoutRequest } = this.props
    const { angle } = this.state

    return (
      <div className={styles.wrapper}>
        <Paper className={styles.navbarWrapper} elevation={4}>
          <Grid container justify='center' spacing={8} className={styles.navbar}>
            <Grid item xs={6}>
              <NadaLinkLogo />
            </Grid>

            { currentUser
              ? <Grid className={styles.loginSection} item xs={6}>
                <Button onClick={userLogoutRequest} className={styles.button}><i className={classNames(styles.buttonIcon, 'fa fa-sign-out')} /> Odjava</Button>
              </Grid>
              : <Grid className={styles.loginSection} item xs={6}>
                <Button onClick={() => push('/login')} className={styles.button}><i className={classNames(styles.buttonIcon, 'fa fa-sign-in')} /> Prijava</Button>
                <Button onClick={() => push('/signup')} className={styles.button}><i className={classNames(styles.buttonIcon, 'fa fa-user-plus')} /> Registracija</Button>
              </Grid> }
          </Grid>
        </Paper>
        <Grid container justify='center' spacing={16} className={styles.body}>
          <Grid item xs={5} className={styles.left}>
            <div className={styles.croatia}>
              <div className={styles.logo}>
                <div className={styles.frame} />
                <div ref={this.onArrowRef} className={styles.arrow} style={{ transform: `rotate(${angle}deg)` }} />
                <div className={styles.text}>NADA</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={5} className={styles.right}>
            <Grid container>
              <Grid className={styles.category} item xs={12}>
                <Grid container justify='center'>
                  <Grid className={styles.iconWrapper} item xs={4}>
                    <i className={classNames(styles.icon, 'fa fa-info')} />
                  </Grid>
                  <Grid className={styles.linksWrapper} item xs={6}>
                    <Link className={styles.link} to='/about'>O nama</Link>
                    <Link className={styles.link} to='/news'>Novosti</Link>
                    <Link className={styles.link} to='/documents'>Dokumenti</Link>
                    <Link className={styles.link} to='/newsletter'>Obavijesti me</Link>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={styles.category} item xs={12}>
                <Grid container justify='center' >
                  <Grid className={styles.iconWrapper} item xs={4}>
                    <i className={classNames(styles.icon, 'fa fa-users')} />
                  </Grid>
                  <Grid className={styles.linksWrapper} item xs={6}>
                    <Link className={styles.link} to='/assembly'>Skupština</Link>
                    <Link className={styles.link} to='/bodies'>Radna Tijela</Link>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={styles.category} item xs={12}>
                <Grid container justify='center' >
                  <Grid className={styles.iconWrapper} item xs={4}>
                    <i className={classNames(styles.icon, 'fa fa-bolt')} />
                  </Grid>
                  <Grid className={styles.linksWrapper} item xs={6}>
                    <Link className={styles.link} to='/propositions'>Prijedlozi</Link>
                    <Link className={styles.link} to='/activities'>Akcije</Link>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={styles.category} item xs={12}>
                <Grid container justify='center' >
                  <Grid className={styles.iconWrapper} item xs={4}>
                    <i className={classNames(styles.icon, 'fa fa-file-text')} />
                  </Grid>
                  <Grid className={styles.linksWrapper} item xs={6}>
                    <Link className={styles.link} to='/candidacies'>Kandidature</Link>
                    <Link className={styles.link} to='/rankings'>Rang Liste</Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={styles.footer}>
          Nezavisna Afirmativna Domoljubna Akcija
        </div>
      </div>
    )
  }

  private onArrowRef = (div: HTMLDivElement) => {
    this.arrow = div
    if (div) {
      window.addEventListener('mousemove', this.onMouseMove, false)
    } else {
      window.removeEventListener('mousemove', this.onMouseMove)
    }
  }

  private onMouseMove = (evt: MouseEvent) => {
    const rect = this.arrow.getBoundingClientRect()
    const center = { x: rect.left + rect.width * 0.5, y: rect.top + rect.height * 0.5 }
    const mouse = { x: evt.clientX, y: evt.clientY }
    const vector = { x: mouse.x - center.x, y: mouse.y - center.y }
    const angle = Math.atan2(vector.y, vector.x) * 180 / Math.PI

    this.setState({ angle })
  }
}

export const Home = compose(
  connect(mapState, mapDispatch),
  // withStyles(styleSheet),
)(HomeC) as React.ComponentClass<IHomePublicProps>

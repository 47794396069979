import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import user from './user'

export default (injectedReducers?: any) =>
  combineReducers({
    // Initial
    user,

    // Vendor
    form,

    ...injectedReducers,
  })

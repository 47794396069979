type Identity = <T>(arg: T) => T
export const identity: Identity = (arg: any) => arg

type Not = <T>(arg: T) => boolean
export const not: Not = (arg: any) => !arg

type Any = <T>(...predicates: Array<(a: T) => boolean>) => (a: T) => boolean
const anyImpl: any = (...predicates: any[]) => (arg: any) => predicates.some((check) => check(arg))
export const any: Any = anyImpl

type All = <T>(...predicates: Array<(a: T) => boolean>) => (a: T) => boolean
const allImpl: any = (...predicates: any[]) => (arg: any) => predicates.every((check) => check(arg))
export const all: All = allImpl

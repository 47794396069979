import { IUserAuthorizedData } from 'ard/api/typescript/Hope/common/UserAuthorizedData'
import { IUserCredentials } from '../../../../ard/api/typescript/Hope/common/UserCredentials'
import { IAction } from './interfaces'

export enum UserActionType {
  SIGNUP_REQUEST = 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS = 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE = 'SIGNUP_FAILURE',

  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  ACTIVATE_USER_REQUEST = 'ACTIVATE_USER_REQUEST',
  ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS',
  ACTIVATE_USER_FAILURE = 'ACTIVATE_USER_FAILURE',

  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'LOGOUT_FAILURE',
}

// Signup
export const userSignupRequest = (payload: IUserCredentials): IAction<UserActionType.SIGNUP_REQUEST, IUserCredentials> =>
  ({ type: UserActionType.SIGNUP_REQUEST, payload })

export const userSignupSuccess = (payload: IUserAuthorizedData): IAction<UserActionType.SIGNUP_SUCCESS, IUserAuthorizedData> =>
  ({ type: UserActionType.SIGNUP_SUCCESS, payload })

export const userSignupFailure = (error: Error[]): IAction<UserActionType.SIGNUP_FAILURE, Error[]> =>
  ({ type: UserActionType.SIGNUP_FAILURE, payload: error })

// Login
export const userLoginRequest = (payload: IUserCredentials): IAction<UserActionType.LOGIN_REQUEST, IUserCredentials> =>
  ({ type: UserActionType.LOGIN_REQUEST, payload })

export const userLoginSuccess = (payload: IUserAuthorizedData): IAction<UserActionType.LOGIN_SUCCESS, IUserAuthorizedData> =>
  ({ type: UserActionType.LOGIN_SUCCESS, payload })

export const userLoginFailure = (error: Error[]): IAction<UserActionType.LOGIN_FAILURE, Error[]> =>
  ({ type: UserActionType.LOGIN_FAILURE, payload: error })

// Logout
export const userLogoutRequest = (): IAction<UserActionType.LOGOUT_REQUEST> =>
  ({ type: UserActionType.LOGOUT_REQUEST, payload: null })

export const userLogoutSuccess = (): IAction<UserActionType.LOGOUT_SUCCESS> =>
  ({ type: UserActionType.LOGOUT_SUCCESS, payload: null })

export const userLogoutFailure = (error: Error[]): IAction<UserActionType.LOGOUT_FAILURE, Error[]> =>
  ({ type: UserActionType.LOGOUT_FAILURE, payload: error })


export type UserAction =
  & ReturnType<typeof userSignupRequest>
  & ReturnType<typeof userSignupSuccess>
  & ReturnType<typeof userSignupFailure>
  & ReturnType<typeof userLoginRequest>
  & ReturnType<typeof userLoginSuccess>
  & ReturnType<typeof userLoginFailure>
  & ReturnType<typeof userLogoutRequest>
  & ReturnType<typeof userLogoutSuccess>
  & ReturnType<typeof userLogoutFailure>

/*
const current = (user: any) => ({ user, type: 'USER_CURRENT' })
const updateActivation = (activatedAt: any) => ({ activatedAt, type: 'USER_ACTIVATED' })

const signup = ({ username, password, confirmPassword, phoneNumber, carrier, oib, firstName, lastName }) => (dispatch) => ajax
  .post({
    url: signupUrl,
    data: { username, firstName, lastName, phoneNumber: `385${carrier.replace('0', '')}${phoneNumber}`, oib, password, confirmPassword },
  })
  .catch(() => Promise.reject('Došlo je do greške, pokušajte ponovno malo kasnije.'))
  .then(response => {
    console.log(response)
    switch (response.status) {
    case 200:
      dispatch(current(response.data.user))
      return Promise.resolve(response.data.user)
    case 403:
      return Promise.reject('Korisničko ime ili lozinka nisu valjani.')
    default:
      return Promise.reject('Došlo je do greške, pokušajte ponovno malo kasnije.')
    }
  })

const login = (username, password) => dispatch => ajax
  .post({
    url: loginUrl,
    data: { username, password },
  })
  .catch(() => Promise.reject('Došlo je do greške, pokušajte ponovno malo kasnije.'))
  .then(response => {
    console.log(response)
    switch (response.status) {
    case 200:
      dispatch(current(response.data.user))
      return Promise.resolve(response.data.user)
    case 403:
      return Promise.reject('Korisničko ime ili lozinka nisu valjani.')
    default:
      return Promise.reject('Došlo je do greške, pokušajte ponovno malo kasnije.')
    }
  })


const logout = () => dispatch => ajax
  .post({
    url: logoutUrl,
  })
  .then(() => dispatch(current(null)))

const fetchCurrentUser = () => dispatch => ajax
  .get({ url: currentUrl })
  .then((response) => response.status === 200 && dispatch(current(response.data)))
  .catch((err) => {
    console.debug(err)
    console.debug('session is not authenticated')
    return {}
  })


const activate = (code) => dispatch => ajax
    .post({ url: '/v1/activate', data: { code } })
    .then((payload) => {
      if (payload.status === 202) {
        dispatch(updateActivation(payload.data.activatedAt))
      }

      return Promise.resolve(payload)
    })


export default {
  current,
  login,
  logout,
  signup,
  fetchCurrentUser,
  activate,
}
*/
